:root {
  --headerH: 25vh;
  --galaxyF: 5vh;
  --galaxyF_MOBILE: 10vh;
  --omrs-color-ink-lowest-contrast: rgb(255, 255, 255);
  --omrs-color-ink-low-contrast: rgb(255, 255, 255);
  --omrs-color-ink-medium-contrast: rgb(255, 255, 255);
  --omrs-color-interaction: #1e4bd1;
  --omrs-color-interaction-minus-two: rgba(73, 133, 224, 0.12);
  --omrs-color-danger: #b50706;
  --omrs-color-bg-low-contrast: #000000;
  --omrs-color-ink-high-contrast: #121212;
  --omrs-color-bg-high-contrast: #000000;
}

* {
  pointer-events: all;
}

@font-face {
  font-family: 'Urwdin bold';
  src: local('Urwdin'), url('./fonts/URWDIN-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Urwdin light';
  src: local('Urwdin'), url('./fonts/URWDIN-Medium.ttf') format('truetype');
}


body {
  font-family: 'Urwdin bold';
  color: #ffffff;
  font-size: 32px;
  text-transform: uppercase;
  -moz-user-select: none;
  /* firefox */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE */
  user-select: none;
  /* Standard syntax */
  overflow: "hidden";
}

html,
body {
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}


.clickable {
  pointer-events: all;
  cursor: pointer;
}

.light {
  font-family: Urwdin light;
}

.MainW {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  border: 0px solid green;
  color: black;
  background: white;
  overflow-x: hidden;
}

.MainB {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  border: 0px solid green;
  background: black;
  color: white;
}

.whitebg {
  background-color: white;
}

.blackbg {
  background-color: black;
}

.header_class {
  font-family: 'Urwdin bold';
  font-size: 2cqw;
  width: 25vw;
  position: fixed;
  left: 50%;
  top: 70px;
  transform: translate(-50%, -50%);
  margin-top: 70px;
  border: 0px solid red;
  text-align: center;
  color: black;
  text-decoration: none;
}

.header_class_white {

  font-family: 'Urwdin bold';
  font-size: 32px;
  border: 0px solid red;
  color: white;
}

.body_class {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: white;

}

.read


.body_content {
  position: absolute;
  bottom: 0;
  border: 0px solid red;
  width: 100vw;
  padding-bottom: 50px;
  cursor: pointer;
}

.body_contentb {
  position: absolute;
  bottom: 0;
  border: 0px solid red;
  width: 100vw;
  padding-bottom: 50px;
  color: white;
  cursor: pointer;
}

.mouse {
  cursor: pointer;
  pointer-events: all;
  color:black;
}

.mouse:hover {
  color: white;
}

.mouse2{
  cursor: pointer;
  pointer-events: all;
  color:black;
}

.underline{
  text-decoration: underline;
}

.mouse2:hover{
  color: var(--omrs-color-interaction);
  cursor: pointer;
}

.mousefb {
  cursor: pointer;
  pointer-events: all;
  color:black;
  display: flex;
  align-items: flex-start; 
}

.mousefb:hover {
  color: #1877f2
}

.mousered {
  cursor: pointer;
  pointer-events: all;
  color:black;
  display: flex;
  align-items: flex-start; 
}

.mousered:hover {
  color: #FF4500
}

.mouseX {
  cursor: pointer;
  pointer-events: all;
  color:black;
  display: flex;
  align-items: flex-start; 
}

.mouseX:hover {
  color: #1DA1F2
}

.mouseWs {
  cursor: pointer;
  pointer-events: all;
  color:black;
  display: flex;
  align-items: flex-start; 
}

.mouseWs:hover {
  color: #075e54
}



.linkOn {
  color: white;
  pointer-events: all;
}

.linkOff {
  color: black;
  pointer-events: all;
}

.linkOff2 {
  color: grey;
  pointer-events: all;
}

.readMoreb {
  font-family: 'Urwdin bold';
  font-size: 16px;
  text-align: left;
  width: 60vw;
  margin: auto;
}

.readMorebMob {
  font-family: 'Urwdin bold';
  font-size: 12px;
  text-align: left;
  width: 80vw;
  margin: auto;
}




.clickDiv {
  pointer-events: all;
  cursor: pointer;
}

.loader {
  border: 11px sold green;
}


.galaxyfooter_class {

  font-family: 'Urwdin bold';
  font-size: 32px;
  position: absolute;
  left: 0;
  bottom: var(--galaxyF);  
  border: 0px solid red;
  width: 99vw;
  text-align: center;
  color: black;
}

.galaxyfooter_class_white {
  position: absolute;
  left: 0;
  bottom: var(--galaxyF);
  border: 0px solid red;
  width: 99vw;
  text-align: center;
  color: rgb(255, 255, 255);
}

input {
  color: #000000;
  background: transparent;
  width: 200px;
  height: 32px;
  font-size: 32px;
  font-family: 'Urwdin bold';
  border: 0;
  border-bottom: 1px solid rgb(0, 0, 0);
  text-align: center;
}

.input_black {
  color: #ffffff;
  background: transparent;
  width: 200px;
  height: 32px;
  font-size: 32px;
  font-family: Urwdin bold;
  border: 0;
  border-bottom: 1px solid rgb(255, 255, 255);
  text-align: center;
  opacity: 1;
  /* required on iOS */
}

input:focus {
  outline-width: 0;
}

input {
  outline: none;
  text-transform: uppercase;
}

.sigInput {
  width: 50vw;
  pointer-events: all;
  color: rgba(0, 0, 0, 0.3);
}


.row {
  border: 0px solid green;
  display: flex;
  width: 100vw;
  display: -webkit-flex;
  -webkit-justify-content: center;

  display: flex;
  justify-content: center;
}

.col-md-2 {
  -webkit-flex: 1;
  flex: 12;
  border: thin solid #777;

}

.InfoIdea {
  font-family: 'Urwdin bold';
  font-size: 32px;
  position: absolute;
  left: -50vw;
  top: -50vh;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding: 10vw;
  background-color: transparent;
  color: #0000FF;
  border: 0px solid red;
  pointer-events: none;
  overflow: hidden;
}

.ExplainInfoIdea {
  font-family: 'Urwdin bold';
  font-size: 32px;
  position: absolute;
  left: -50vw;
  top: -50vh;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding: 10vw;
  background-color: transparent;
  color: #000000;
  border: 0px solid red;
  pointer-events: none;
  overflow: hidden;
}

.InfoIdea_white {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 10vw;
  background-color: transparent;
  color: #9d9d9d;
  border: 0px solid red;
  pointer-events: all;
  overflow: hidden;
}


.Listing {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  color: #000
}

.Listing .bbutton {
  position: absolute;
  left: 0;
  bottom: 10px;
}

.post {
  background-color: #eee;
  margin: 1rem;
  padding: 1rem;
  font-size: 10px;
}

.pagination {
  margin: 1rem auto;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  pointer-events: all;
  cursor: pointer;
  position: absolute;
  left: 100px;
  bottom: 10px;
}

.active {
  border: 1px solid black;
  border-radius: 100%;
  padding: 0 3px;
  outline: none;
}

.fullGalaxy {
  border: 1px solid 0;
  height: 100vh;
  width: 100vw;
}


.seriesOn {
  color: blue;
  pointer-events: all;
  cursor: pointer;
  text-decoration: underline;
}

.seriesOff {
  color: rgb(0, 0, 0);
  pointer-events: all;
  cursor: pointer;
}

.createone {
  color: #0906b5;
  border: 0px solid red;
  margin-left: 0px;
  display: inline-block;
  line-height: 8vh;
  vertical-align: middle;

}

.createone_white {
  color: #ffffff;
  border: 0px solid green
}

.newOptions_class {
  font-size: 32px;
  border: 0px solid red;
  text-align: center;
  }

.messages {
  font-family: 'Urwdin bold';
  font-size: 64px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100vw;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1000;
}

.messageMobile {
  font-family: 'Urwdin bold';
  font-size: 32px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100vw;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.alert-shown {
  position: absolute;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  align-items:center;
  justify-content:center;
  opacity: 0;
  border:0px solid red;
  pointer-events: none;
}


.AlertfadeOut{
  position: absolute;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  align-items:center;
  justify-content:center;
  opacity: 0;
  border:0px solid red;
  animation-name: Out;
  animation-duration: 4s;
  pointer-events: none;
}

.alert-hidden {
  opacity: 0;
}

.widthhundred {
  width: 100vw;
  border: 0px solid red;
}

.galaxyLeft_class_open {
  border: 0px solid #b50706;
  position: absolute;
  padding: 70px 0;
  top: 0px;
  right: 0px;
  width: 8vw;
  height: calc(100vh - 140px);
}

.galaxyLeft_class_close {
  border: 0px solid #b50706;
  position: absolute;
  padding: 70px 0;
  top: 0px;
  right: 0px;
  width: 8vw;
  height: calc(100vh - 140px);
  opacity: 0;
}

.closeButton {
  position: absolute;
  bottom: var(--galaxyF);
}


.selected{
  color:black;
}

.selected:hover {
  color:#0000FF;
  
}

.waiting {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  color: #ffffff;
  border: 1px solid red;
}

.mobile_fontsize {
  font-size: 16px;
  width: 100vw;
}


.gfooter_mobile {
  position: absolute;
  left: 0;
  bottom: var(--galaxyF_MOBILE);
  border: 0px solid red;
  width: 99vw;
  text-align: center;
  color: black;
  height: 10vh;
  font-size: 10px;
}

.InfoIdea_mobile {
  position: absolute;
  left: 0px;
  top: 20vw;
  width: 100vw;
  height: 20vh;
  box-sizing: border-box;
  padding: 0vw;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
  color: #000000;
  border: 0px solid red;
  pointer-events: all;
  overflow: hidden;
  font-size: 14px;
}

.swipeMe {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: calc(100vh - var(--galaxyF_MOBILE) - 100px);
  border: 0px solid red;
  pointer-events: all;
}

.debug {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 15px;
}

.foundfilter {

  font-family: 'Urwdin bold';
  font-size: 32px;
  border: 0px solid red;
  width: 50vw;
  height: 50vh;
  pointer-events: all;
}

.Listing {
  font-family: 'Urwdin bold';
}

.pagination {
  font-family: 'Urwdin bold';
  color: black;
  width: 100vw;
  border: 0px solid red;
  margin-left: -100px;
}



.card-title {
  width: 100px;
  border: #5c5c5c 1px solid;
  width: 100%;
  height: 150px;
  border-radius: 10px;
  padding: 10px
}

.active {
  color: blue;
}

.btn-primary {
  width: 100%;
}

.paginationList {
  font-family: 'Urwdin bold';
  margin: 1rem auto;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  pointer-events: all;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
}

.activeList {
  border: 0px solid black;
  padding: 0 3px;
  outline: none;
  color: #0906b5;
  text-decoration: underline;
}

.card-body .cardBody {
  display: flex;
  justify-content: space-evenly;
}


.card-group {
  display: flex;
  border: 1px solid red;
  width: 1240px;
  height: 720px;
}

.OutsideStripe {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid rgb(85, 255, 0);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.htmlStripe {
  border: 1px solid red;
  width: 50vw;
  height: 50vh;
  display: flex;

}


.htmlStripe#content iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  border: #1e4bd1 solid 1px;
}

.paidButton {
  position: absolute;
  border: 0px solid red;
  bottom: var(--galaxyF);
  width: 100vw;
  text-align: center;
  font-size: 32px;
  color: black;
  font-family: 'Urwdin bold';
  text-transform: uppercase;
}

.paidThanks{
  text-align: center;
  font-size: 32px;
  color: black;
  font-family: 'Urwdin bold';
  text-transform: uppercase;
}

.blob{
  border: 1px solid red;
}


.passthrough {
  pointer-events: none;
}

.input_mobile {
  color: rgb(0, 0, 0);
  font-size: 16px;
  pointer-events: all;
  opacity: 1;
  border: #ff0000 0px solid;
  border-bottom: 1px solid rgb(0, 0, 0);
  width: 30vw;
  margin: auto;
  height: 16px;
}

.mobile_header {
  border: 0px solid green;
  position: absolute;
  top: -20px;
}

.App {
  position: absolute;
  bottom: 0px;
  width: 100vw;
  font-size: 15px;
  color: blue;
}



.clickmetochange {
  border: 0px solid green;
  height: 100px;
}

.walletMobile {
  margin: auto;
  border: 0px solid rebeccapurple;
  position: absolute;
  bottom: 10px;
  width: 100vw;
  padding: auto;
  display: flex;
  align-items: center;
  justify-content: center;

}

.hg-button[data-skbtn="{space}"] {
  width: 50%;
}

.buttons {
  position: inherit;
  top: 10vw;
  right: 10vw;
  display: flex;
  flex-direction: column;
}

.buttons img {
  margin-right: 13px;
}


.smallerLetters {
  font-size: 12px;
  z-index: 10000;
}

.closebutton {
  border: 0px solid red;
  width: 50px;
  text-align: center;
}

@font-face {
  font-family: 'NewFont';
  src: url('./fonts/NewFont-Regular.eot') format('eot');
  src: local('NewFont'), url('./fonts/NewFont-Regular.woff2') format('woff2'), url('./fonts/NewFont-Regular.woff') format('woff');
}


  
  .top { 
    position: absolute;
    left: calc(50vw - 125px);
    top: 100px;
    width: 250px;
    height: 50px;
    z-index: 2; 
    border: 0px solid red;
  }

  .content { 
    grid-area: 3 / 3 / 9 / 9; 
    border:0px solid green;
    color: blue; 
    font-size : 54px; 
    font-Family: 'NewFont';
    background: transparent; 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .menuinfo { 
    grid-area: 9 / 3 / 10 / 9; 
    display: flex;
    justify-content: center;
    z-index: 2;
    position: relative;
    top:10vh;
  }

  .Small { 
    grid-area: 10 / 3 / 11 / 9; 
    font-size: 12px;
    z-index: 2;
  }
  .back { 
    z-index: 2;
    grid-area: 1 / 1 / 2 / 3; 
    border: 0px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    }

.canvasDiv{
  border:0px solid red;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

.canvasNew{
  border:0px solid red;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
    z-index: -1;
}

.footerClicker{
  z-index: 2;
}


.controlNewStages {
  border:0px solid red;
  display: flex;
  flex-direction: row;
  justify-content:center;
}

.controlNewStages .Btn{
  margin-left: 10px;
  margin-right: 10px;
  width: 25vw; 
}

.controlNewStages .Info{
  width: 50vw;
  min-width: 250px;
}


.sigdiv{
  width: 50vw;
  height: 60px;
  outline: none;
  margin-top: 20px;
  border: 0px solid red;;
}

.Moneydiv{
  width: 50vw;
  height: 60px;
  outline: none;
  margin-top: 20px;
  border: 0px solid red;;
  display: flex;
  flex-direction: row;
}

.sig{
  width: 50vw;
  height: 40px;
  outline: none;
  margin-top: 20px;
}

.content .moneyArea{
  width: 30ch;
}

.content input:focus{
  outline: none;
}

.content .seriesOff{
  color:#777;
  opacity: .2;
}

.backToGalaxy{
  color: black;
  width: 320px;
  position: relative;
  bottom: 40vh;
  right: 40vw;
  z-index: 1000;
}



.overlay{
    background-color: rgb(0,0,0,.2);
    width: 140vw;
    height: 140vh;
    position: relative;
    bottom: 65vh;
    right: 60vw;
   
  }
  
  .wouldYou{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    text-align: center;
  }

  
  .full{
      position: absolute;
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      align-items:flex-end;
      justify-content:flex-start;
      opacity: 0;
      border:0px solid red;
      animation-name: In;
      animation-duration: 4s;
  }


 .fadeOut{
      position: absolute;
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      align-items:flex-end;
      justify-content:flex-start;
      opacity: 0;
      border:0px solid red;
      animation-name: Out;
      animation-duration: 4s;
} 


@keyframes Out {
  0%   {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes In {
  0%   {opacity: 0;}
  100% {opacity: 1;}
}


.showprice{
  opacity: 1;
}

.hiddenprice{
  opacity: 0;
}

.showprice, .hiddenprice{
  width: 40vw;
}

.moreinfo{
    height: 40px;
    position: absolute;
    left:100px;
    top:100px;
}

/* new */


.layerControls{
  position: absolute;
  bottom:150px;
  left: 0px;
  border:0px solid rebeccapurple;
  width: 100vw;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}


.Prev { 
  position: absolute;
  left:200px;  
}

.Next {
  position: absolute;
  right: 200px;
 
}
 
.stages{
  border:0px solid rebeccapurple;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size : 54px; 
  font-Family: 'NewFont'; 
  color: blue; 
}

.Newstage1{
  font-size : 32px; 
   padding: 10vw;
  }



.stages .login{
  color: black;
  font-family: 'Urwdin bold';
  font-size: 32px;
  text-transform: uppercase;
}




textarea{
  width: 50vw;
  height: 50vh;
  color: blue; 
  font-size : 54px; 
  font-Family: 'NewFont'; 
  background: transparent;
  border:0px solid green;
  line-height: 60px;
  background-attachment: local;
  background-image:
      repeating-linear-gradient(white, white 60px, #ccc 60px, #ccc 61px, white 61px);
  resize: none;
    }

    textarea:focus, input:focus{
      outline: 0;
  }

  .miltosTextArea{
    width: 100%;
    
  }
  .miltosText{
    padding: 50px;
    width: 100vw;
    border: 0px solid red;
    box-sizing: border-box;  
  }
  
.MiltosList{
  font-size: calc(1vw + 1vh + 0vmin);;
  margin-bottom: 20px;
}

.MiltosRow{
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 100vw;
  border:1px dotted black;
  overflow: hidden;
  

}

.MiltosRow *{
  min-height: 100%;
  align-content: center;
}

.Miltocommands{
  min-width: 150px;
  border: 0px solid red;
}

.content textarea:focus{
  outline: none;
}






#stage1{
  display: none;
}

.options{
  display: flex;
  flex-direction: row; 
  margin-top: 20px;
}

.options * {
  margin-left: 20px;
  margin-right: 20px;
}

.option1{
  color: gray;
}

.option2{
  color: gray;
}

.newbackToGalaxy{
  color: black;
  width: 320px;
  position: absolute;
  top: 100px;
  left: 100px;
  z-index: 1000;
}

.coin{
  font-size: 30px;
  display: flex;
  align-items: flex-end;
}

.login{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0px solid red;
  height: 80vh;
  width: 100%;
}

.buttonsAction{
  display: flex;
  flex-direction: column;
  border: 0px solid red;
  width: 20vw;
  position: absolute;
  right: 0px;
}

.buttonsShare{
  display: flex;
  flex-direction: column;
  border: 0px solid red;
  width: 20vw;
  position: absolute;
  bottom: 200px;
}

.buttonsLogin{
  display: flex;
  flex-direction: column;
  border: 0px solid red;
  width: 20vw;
  position: absolute;
  bottom: 200px;
  right: 0px;
}


.LoginButtons  .btt{
  font-size: 30px;
}

.LoginButtons  .btt:hover{
  color:blue;
  cursor: pointer;
}


.login .email, .login .password{
  border:0px solid red;
  width: 30vw;
  border-bottom: 1px solid black;
  font-family: "Roboto", sans-serif;
  font-weight: 60;
  font-style: normal;
  text-transform: none;
}
 
.LoginButtons{
  border: 0px solid red;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  gap:20px
}

.loginMessage{
  border:0px solid green;
  position: fixed;
  left: 50%;
  top: 20vh;
  transform: translate(-50%, -50%);
}

.profile{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  width: 100vw;

}

.profile .left{
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.profile .right{
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Email{
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.house{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.house input{
  font-family: "Roboto", sans-serif;
  font-weight: 60;
  font-style: normal;
  text-transform: none;
}

.feedbackLayer{
  color: var(--omrs-color-interaction)
}

.phrase{
  color: var(--omrs-color-interaction);
  padding-left: 150px;
  height: 100vh;
  display: flex;
  align-items: center;
  font-size : 30px; 
  font-Family: 'NewFont'; 
}

.buyMain{
  border:0px solid black;
  display: flex;
  flex-direction: column;
}

.buyfooter{
  display: flex;
  flex-direction: row;  
}

.buyfooter div{
  width: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

.buyfooter .bright{
  display: flex;
  justify-content: flex-end;
}

.buy2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0px solid red;
  height: 80vh;
  width: 30vw;
}

.paybutton{
  border:0px solid red;
  margin-top: 10px;
  font-size: 32px;
  color: black;
  font-family: 'Urwdin bold';
  text-transform: uppercase;
}

.payinfo{
  font-size: 20px;
  color: black;
  font-family: 'Urwdin bold';
  text-transform: uppercase;

}

td{
  border: 0px solid red;
  text-align: right
}