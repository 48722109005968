

.MuiContainer-root * {
    pointer-events: all;
    font-size : 20px; 
    font-Family: 'NewFont' !important;
}

.MuiTableRow-root{
    border:1px solid red
}

table.mat-table {
    display: table;
}

.BuroTable{
    border: 1px solid red;
}